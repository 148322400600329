import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
import useOffSetTop from '../../hooks/useOffSetTop';
import { bgBlur } from '../../utils/cssStyles';
import { HEADER } from '../../config-global';

import NavMobile from './nav/mobile';
import navConfig from './nav/config-navigation';
import NavDesktop from './nav/desktop';
import { isMobile } from 'react-device-detect';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

interface HeaderProps {
  children?: React.ReactNode;
}

export default function Header({ children }: HeaderProps) {
  const theme = useTheme();
  const [safeAreaInsetTop, setSafeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP - safeAreaInsetTop - 20);

  return (
    <AppBar
      color="transparent"
      sx={{
        boxShadow: 0,
      }}
    >
      <Toolbar
        disableGutters
        id="header-main"
        sx={{
          height: HEADER.H_MAIN_DESKTOP + safeAreaInsetTop / 4,
          paddingTop: `${safeAreaInsetTop}px`,
          borderBottom: (theme) => `solid 1px transparent`,
          backgroundColor: 'rgba(0,0,0,0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0px 4px 20px rgba(0,0,0,0.2)',
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex' }}>
          {!isMobile && <NavDesktop isOffset={isOffset} data={navConfig} />}

          {isMobile && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

interface ShadowProps {
  sx?: object;
}

function Shadow({ sx, ...other }: ShadowProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows?.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
